import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import { Odin } from '../src/components/Odinv2/Odin';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Hod from '../src/components/Hod/Hod';
import './dossier_prensa.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query dossierQuery($locale: String!) {
    allDossierSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allDossierTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allDossierHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDossierBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allDossierOdinBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          ctaText
          cta
          localImage {
            childImageSharp {
              fluid(maxWidth: 1400, maxHeight: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDossierRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class DossierPrensa extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allDossierTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allDossierHeaderImage.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allDossierHeaderImage.edges[0].node.alt,
      },
    };

    const odinData = {
      image: this.props.data.allDossierOdinBlock.edges[0].node.localImage.childImageSharp.fluid,
      alt: this.props.data.allDossierOdinBlock.edges[0].node.image_alt,
      title: this.props.data.allDossierOdinBlock.edges[0].node.title,
      description: this.props.data.allDossierOdinBlock.edges[0].node.description,
      titleType: 'h3',
      buttons: {
        cta1: this.props.data.allDossierOdinBlock.edges[0].node.cta,
        ctaText1: this.props.data.allDossierOdinBlock.edges[0].node.ctaText,
        size: 'alone',
        color1: 'white',
        blank: true,
      },
    };

    const h2Data = {
      title: this.props.data.allDossierTitleBlock.edges[0].node.subtitle,
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allDossierSeoBlock.edges[0].node._0.title}
          description={this.props.data.allDossierSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allDossierRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allDossierHeaderImage.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="dossier-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allDossierBreadcrumbBlock.edges[0].node.name}
              />
              <H2 data={h2Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default DossierPrensa;
